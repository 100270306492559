@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';
@import 'styles/colors-2024';
@import 'styles/layout-tokens';

.modal_container {
	position: fixed;
	inset: 0;
	display: flex;
	flex-direction: column;
	overflow: auto;
	min-height: 100dvh;
	width: 100vw;
	z-index: 1300;

	background-color: rgba(0, 0, 0, 0.7);
	transition: opacity 0.75s ease-out, transform 0.75s ease-out;

	&.none {
		opacity: 0;
		pointer-events: none;
	}
}

.quiz_trigger_button {
	display: flex;
	align-items: center;
	gap: 8px;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	color: $text-white !important;
	font-size: 0.875rem;

	&:hover {
		text-decoration: underline;
	}

	@include xs-only {
		font-size: 0.75rem !important;

		> svg {
			width: 1rem;
			height: 1rem;
		}
	}
}

.modal_wrapper_content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	position: relative;
	width: 100%;
	height: 100%;
	flex: 1;
	overflow: scroll;

	@include sm-down {
		display: flex;
		flex-direction: column;
	}
}

.left_lines_wrapper {
	position: absolute;
	top: 35%;
	left: 0;
	width: 52px;
	height: 256px;
	z-index: 1;

	> svg {
		width: 100%;
		height: 100%;
	}

	@include sm-down {
		width: 26px;
		height: 128px;
	}
}

.bottom_lines_wrapper {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 270px;
	height: 211px;
	z-index: 1;

	> svg {
		width: 100%;
		height: 100%;
	}

	@include sm-down {
		width: 135px;
		height: 105.5px;
	}
}

.top_lines_wrapper {
	position: absolute;
	top: 40px;
	right: 0px;
	width: 134px;
	height: 92px;
	z-index: 1;

	> svg {
		width: 100%;
		height: 100%;
	}

	@include sm-down {
		width: 67px;
		height: 46px;
		top: 0;
		left: 0;
	}
}

.quiz_header {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 50px 32px 0 32px;

	@include sm-down {
		padding: 1.25rem;
	}
}

.quiz_header_splash_screen {
	position: absolute;
	top: 0;
	right: 0;
	align-items: flex-end;
	z-index: 3;

	@include sm-down {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}

.quiz_header_top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 52px;
	gap: 1.5rem;

	@include sm-down {
		margin-bottom: 1.5rem;
	}
}

.quiz_question_container {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	width: 100%;
	border: 1px solid rgba(255, 255, 255, 0.02);
	background: linear-gradient(139deg, #122b67 4.86%, #000 72.43%);

	@include sm-down {
		display: flex;
		flex-direction: column;
	}
}

.header_button {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	width: 32px;
	height: 32px;

	& svg {
		width: 100%;
		height: 100%;
	}
}

.desktop_logo_wrapper {
	@include sm-down {
		display: none;
	}
}

.header_logo {
	height: 56px;
}

.header_logo_wrapper {
	display: none;

	@include sm-down {
		display: block;

		svg {
			min-height: 1.4rem;
			min-width: initial;
			height: 1.4rem;
		}
	}
}

.splash_screen_logo_wrapper {
	margin-bottom: 4.31rem;

	@include sm-down {
		display: none;
	}
}

.progress_bar_track {
	position: relative;
	width: 100%;
	border-radius: 16px;
	height: 6px;
	background: $neutral-grey-4;
}

.desktop_progress_bar_track {
	@include sm-down {
		display: none;
	}
}

.mobile_progress_bar_track {
	display: none;

	@include sm-down {
		display: flex;
		height: 0.125rem;
	}
}

.progress_bar {
	position: absolute;
	width: 100%;
	height: 100%;
	background: $white;
	border-radius: 16px;
	transition: width 0.3s ease-in-out;
}

.splash_screen_button {
	margin-top: 2.5rem;
}

.sticky_button {
	margin-bottom: 2.5rem;
}

.quiz_content_body_container {
	display: flex;
	flex: 1;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	align-items: center;

	@include sm-down {
		align-items: flex-start;
	}
}

.quiz_content_body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 50px;
	height: 100%;
	width: 100%;
	max-width: 44rem;
	position: relative;
	z-index: 2;

	@include sm-down {
		padding: 2.5rem 1.25rem;
		justify-content: flex-start;
	}
}

.quiz_content_body_sticky {
	@include xs-only {
		justify-content: space-between;
	}
}

.quiz_content_body_top {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 4rem;

	@include xs-only {
		gap: 2.5rem;
	}
}

.left_image_wrapper {
	position: sticky;
	top: 0;
	height: 100dvh;

	width: 100%;
	background: linear-gradient(0deg, #122b67 4.86%, #000 72.43%);

	@include sm-down {
		position: relative;
		height: 351px;
	}

	@include xs-only {
		position: relative;
		height: 242px;
	}
}

.complete_screen_left_image_wrapper {
	@include xs-only {
		display: none;
	}
}

.left_image {
	max-height: 100dvh;
	> img {
		object-position: 50% 80%;
		height: 100%;
	}

	@include sm-down {
		> img {
			object-position: 100% 50%;
		}
	}
}

.complete_screen_left_image {
	> img {
		object-position: 50% 90%;
	}
}

.modal_video {
	height: 100%;

	> video {
		height: 100%;
		object-fit: cover;
		object-position: left;
	}

	@include sm-down {
		> video {
			object-position: center;
		}
	}
}

.quiz_answers {
	display: flex;
	row-gap: 10px;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 40px;

	@include sm-down {
		margin-top: 30px;
		margin-bottom: 25px;
	}
}

.quiz_button {
	width: 100%;
	max-width: 500px;

	// prevents bug where on mobile, tapping a button keeps the hover state
	// applied to a different button on the next screen in the same area of the screen that was tapped on the previous screen
	// using a react ref to blur the button did not work
	@media (hover: none) {
		&:hover::before {
			opacity: inherit !important;
			background: $white !important;
			box-shadow: none !important;
		}
	}
}

.quiz_button_container {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.sticky_button_wrapper {
	width: 100%;
	position: sticky;
	bottom: 0rem;
	width: 100%;

	// backdrop-filter: blur(5px);
	// mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 2.5rem);
}

.blurred_background {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	backdrop-filter: blur(5px);
	mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 40%);
}

.skip_button {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	background: none;
	outline: none;
	border: none;
	color: $blue-primary;
	font-weight: 500;
	font-size: 1rem;
	cursor: pointer;
	width: fit-content;
	align-self: center;

	> svg {
		width: 0.75rem;
		height: 0.75rem;
	}
}

.headline {
	color: $text-white;
}

.quiz_question_email {
	@include xs-only {
		max-width: 15ch;
	}
}

.headchapter {
	color: $text-grey-light;
}

.did_you_know_wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 2.5rem;
}

.did_you_know_wrapper_h4 {
	color: $text-white;
}

.quiz_content_wrapper {
	max-width: 430px;
	width: 100%;
	margin: 40px auto 0;
}

.pod_features_wrapper {
	display: flex;
	flex-direction: column;
	gap: 1.13rem;
	border-radius: 0.75rem;
	background: rgba(255, 255, 255, 0.08);
	padding: 1.88rem;
	text-align: left;
	font-size: 1.125rem;
	margin-top: 1.25rem;

	@include sm-down {
		border-radius: 0.5rem;
		padding: 1.25rem;
		font-size: 0.8125rem;
	}
}

.benefits_text_bold {
	font-weight: 500;
	color: $text-white;
}

.pod_feature {
	display: flex;
	gap: 1.12rem;
}

.pod_feature_icon {
	flex-shrink: 0;
	width: 2.25rem;
	height: 2.25rem;

	@include sm-down {
		width: 1.5rem;
		height: 1.5rem;
	}
}

.quiz_form {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.email_form_input {
	color: $text-white;
	background: none;
	width: 100%;
	font-size: 16px;
	padding-bottom: 10px;
	min-height: 30px;
	border: none;
	outline: none;
	border-radius: 0;
	border-bottom: 1px solid $white !important;
	box-shadow: none;

	&:focus {
		outline: none;
	}
}

.headline_break_lines {
	@include xs-only {
		max-width: 12ch;
		margin: 0 auto;
	}
}

.sleep_snapshot_table_wrapper {
	display: flex;
	flex-direction: column;
	gap: 1.44rem;
	width: 100%;
	background: rgba(255, 255, 255, 0.08);
	padding: 1.44rem;
	margin: 0 auto;
	max-width: 30rem;
	border-radius: 0.75rem;

	@include xs-only {
		border-radius: 0.5rem;
		max-width: 20rem;
	}
}

.sleep_snapshot_table {
	width: 100%;
	margin: 0 auto;
	border-collapse: collapse;

	tr td {
		padding: 1.44rem 0rem;
		border-bottom: 1px solid $white;
		text-align: left;
	}

	tr td:first-child {
		padding-right: 1rem;
		max-width: 32px;
	}

	tr td:nth-child(2) {
		width: 100%;
	}

	tr td:last-child {
		text-align: right;
	}

	tr:first-child td {
		border-top: 1px solid $white;
	}
}

.sleep_snapshot_table_icon {
	& path {
		fill: $white;
	}
}

.sleep_snapshot_analysis_green {
	color: $text-success-green-dark-mode;
}

.sleep_snapshot_analysis_red {
	color: $text-error-red-light-mode;
}
