@import 'styles/mixins/media-queries';
@import 'styles/colors-2024';

.container {
	padding: 0;
	background-color: transparent;
	display: inline-block;
	color: $text-black;
	margin: 0 0.2em;
}

.bell {
	margin-right: 4px;
	position: relative;
	top: -2px;
}

.right_side {
	display: flex;
	flex-direction: column;
}

.upper_container {
	display: flex;
	margin-bottom: 0;
}

.dates {
	display: flex;
}

.label {
	font-size: 14px;
	line-height: 1.4;
	margin-right: 5px;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	@include xs-only {
		font-size: 12px;
		min-width: 0;
	}
}

.date_unit_container {
	display: flex;
	margin-right: 6px;

	.number,
	.unit {
		font-size: 0.875rem;
		line-height: 140%;
		font-weight: 500;
		margin: 0;

		@include xs-only {
			font-size: 0.75rem;
		}
	}

	.number {
		padding: 0 0.25rem;
		margin: 0 3px 0 0;
		border-radius: 0.125rem;

		background: rgba(255, 255, 255, 0.2);

		@include xs-only {
			font-size: 0.75rem;
		}
	}
}
